<div class="home">
  <div class="home__heading">
    <span>Build Faster, Smarter, Stronger with Our API</span>
    <h1>AI Creative Studio API First Month FREE</h1>
    <p>Focus on Innovation, Not Infrastructure. Our API is Your AI Powerhouse.</p>
    <div class="home__heading-btn">
      <button class="button" (click)="openSignupModal()">Enquire now</button>
      <img src="assets/images/home/robot.png" alt="GAIMIN AI" />
    </div>
    <h2>We have created an all-in-one suite for hosting AI models</h2>
    <h3>
      GAIMIN AI is a collection of advanced AI models leveraging decentralized computing to enhance your AI experience.
    </h3>
  </div>
  <div class="home__tabs">
    <drag-scroll [drag-scroll-y-disabled]="true" [scroll-x-wheel-enabled]="true" [snap-disabled]="true">
      <div
        class="home__tabs-item"
        *ngFor="let tab of tabs; let i = index"
        [class.active]="selectedTabIndex === i"
        (click)="selectTab(i)">
        <span [class]="tab.icon"></span>{{ tab.label }}
      </div>
    </drag-scroll>

    <div class="home__tabs-content" [ngSwitch]="selectedTabIndex">
      <div>
        <ng-container *ngSwitchCase="0">
          <h3>Text to image GAIMIN generation API</h3>
          <p>Create content, automate responses, or personalize experiences with AI text generation.</p>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <h3>Transform words into stunning visuals.</h3>
          <p>
            Improve your product by integrating AI-driven image generation, offering users high-quality, unique visuals.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <h3>Easily convert speech to text.</h3>
          <p>Enhance accessibility and productivity by integrating real-time speech recognition into your products.</p>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <h3>Bring your apps to life with natural AI speech.</h3>
          <p>Use this API to create voiceovers, enhance accessibility, or build interactive experiences.</p>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <h3>Synchronize voice and video seamlessly.</h3>
          <p>Use this API to sync speech with facial movements for lifelike animations and improved video quality.</p>
        </ng-container>
        <a href="https://api.cloud.gaimin.io/ai/redoc" target="_blank">
          API Documentation <span class="icon-Arrow-Right"></span>
        </a>
      </div>
      <img src="assets/images/home/tab-img.png" alt="GAIMIN AI" />
    </div>
  </div>
  <div class="home__features">
    <div class="home__features-wrapper">
      <h2>Feature Packed</h2>
      <h3>Advanced AI features for easy integration into your products.</h3>
      <div class="home__features-list">
        <div class="home__features-list-column">
          <div class="home__features-list-item">
            <img src="assets/images/home/features/img1.png" alt="GAIMIN AI" />
            <h4>Increased Efficiency</h4>
            <p>Automates repetitive tasks and streamlines workflows.</p>
          </div>
          <div class="home__features-list-item">
            <img src="assets/images/home/features/img2.png" alt="GAIMIN AI" />
            <h4>Competitive Advantage</h4>
            <p>Stay ahead with advanced AI powered by a global, decentralized network of cutting-edge computers.</p>
          </div>
        </div>

        <div class="home__features-list-column">
          <div class="home__features-list-item">
            <img src="assets/images/home/features/img3.png" alt="GAIMIN AI" />
            <h4>Data-Driven Decision Making</h4>
            <p>Gain valuable insights from data to make informed business decisions.</p>
          </div>
          <div class="home__features-list-item">
            <img src="assets/images/home/features/img4.png" alt="GAIMIN AI" />
            <h4>Enhanced Customer Experience</h4>
            <p>Provides personalized recommendations, improving customer satisfaction.</p>
          </div>
        </div>

        <div class="home__features-list-column">
          <div class="home__features-list-item">
            <img src="assets/images/home/features/img5.png" alt="GAIMIN AI" />
            <h4>Reduced Latency</h4>
            <p>
              Leveraging a decentralized setup benefits real-time applications like in-game AI and live content
              moderation.
            </p>
          </div>
        </div>
      </div>

      <div class="home__features-config">
        <div class="home__features-config-title">Pre-Configured Solutions</div>
        <div class="home__features-config-text">
          Pre-configured solutions designed to meet specific business needs. Save time by using the GAIMIN AI solutions,
          eliminating the need for manual setup
        </div>
        <button class="button" (click)="openSignupModal()">Start Using the API 🚀</button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
