import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SuccessModalComponent } from '../../../components/modals/success-modal/success-modal.component';
import { ActionsService } from '../../../services/actions.service';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {
  @ViewChild('phone') phoneField: ElementRef | null = null;

  nameMinLength: number = 2;
  nameMaxLength: number = 100;
  phoneMinLength: number = 7;
  phoneMaxLength: number = 15;

  form: FormGroup = new FormGroup<any>({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[ -][a-zA-Z]+)*[a-zA-Z]$')
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [
      Validators.minLength(this.phoneMinLength),
      Validators.maxLength(this.phoneMaxLength),
      Validators.pattern('^\\+[0-9]*$')
    ]),
    company: new FormControl('', [
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z0-9]+(?:[ -]?[a-zA-Z0-9]+)*$')
    ]),
    agreement: new FormControl(false, [Validators.requiredTrue]),
    policy: new FormControl(false, [Validators.requiredTrue])
  });

  constructor(
    private dialogRef: MatDialogRef<SignupModalComponent>,
    private actionsService: ActionsService,
    private dialogService: MatDialog
  ) {}

  ngOnInit(): void {}

  onKeyup(value?: unknown) {
    const previousValue = this.form.value.phone;
    if (previousValue.length > 0 && previousValue[0] !== '+' && previousValue.indexOf('+') < 0) {
      this.form.get('phone')?.setValue('+' + previousValue);
    }
  }

  onMobilePhoneClick() {
    if (!this.form.value.phone || this.form.value.phone === '') {
      this.form.get('phone')?.setValue('+');
    }
  }

  allowOnlyNumbers(event: any) {
    const allowedRegex = /\d/g;
    if (!allowedRegex.test(event.key)) {
      event.preventDefault();
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const { name, email, phone, company } = this.form.value;
    this.actionsService.keyRequest({ name, email, phone, company }).subscribe((response) => {
      if (response.success) {
        this.closeModal();
        this.dialogService.open(SuccessModalComponent, {
          panelClass: ['dialog-overlay-pane', 'dialog-success']
        });
      }
    });
  }

  @HostListener('document:click', ['$event']) onClick(event: any) {
    if (!this.phoneField?.nativeElement.contains(event.target)) {
      if (this.form.value.phone === '+') {
        this.form.get('phone')?.setValue('');
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
